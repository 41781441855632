import { render, staticRenderFns } from "./SigninForm.vue?vue&type=template&id=131996a8&scoped=true&"
import script from "./SigninForm.ts?vue&type=script&lang=ts&"
export * from "./SigninForm.ts?vue&type=script&lang=ts&"
import style0 from "./SigninForm.vue?vue&type=style&index=0&id=131996a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131996a8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2328814865/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('131996a8')) {
      api.createRecord('131996a8', component.options)
    } else {
      api.reload('131996a8', component.options)
    }
    module.hot.accept("./SigninForm.vue?vue&type=template&id=131996a8&scoped=true&", function () {
      api.rerender('131996a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/Auth/components/SigninForm/SigninForm.vue"
export default component.exports