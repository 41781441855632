import { render, staticRenderFns } from "./ClientSection.vue?vue&type=template&id=12ad3778&scoped=true&"
import script from "./ClientSection.ts?vue&type=script&lang=ts&"
export * from "./ClientSection.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ad3778",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2328814865/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12ad3778')) {
      api.createRecord('12ad3778', component.options)
    } else {
      api.reload('12ad3778', component.options)
    }
    module.hot.accept("./ClientSection.vue?vue&type=template&id=12ad3778&scoped=true&", function () {
      api.rerender('12ad3778', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/Cups/components/tabs/GeneralTab/ClientSection/ClientSection.vue"
export default component.exports