import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=4dacf7ce&scoped=true&"
import script from "./Form.ts?vue&type=script&lang=ts&"
export * from "./Form.ts?vue&type=script&lang=ts&"
import style0 from "./Form.vue?vue&type=style&index=0&id=4dacf7ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dacf7ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2328814865/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4dacf7ce')) {
      api.createRecord('4dacf7ce', component.options)
    } else {
      api.reload('4dacf7ce', component.options)
    }
    module.hot.accept("./Form.vue?vue&type=template&id=4dacf7ce&scoped=true&", function () {
      api.rerender('4dacf7ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/Holidays/components/Form/Form.vue"
export default component.exports