import { render, staticRenderFns } from "./HistoricalReports.vue?vue&type=template&id=064c5bef&scoped=true&"
import script from "./HistoricalReports.ts?vue&type=script&lang=ts&"
export * from "./HistoricalReports.ts?vue&type=script&lang=ts&"
import style0 from "./HistoricalReports.vue?vue&type=style&index=0&id=064c5bef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064c5bef",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2328814865/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('064c5bef')) {
      api.createRecord('064c5bef', component.options)
    } else {
      api.reload('064c5bef', component.options)
    }
    module.hot.accept("./HistoricalReports.vue?vue&type=template&id=064c5bef&scoped=true&", function () {
      api.rerender('064c5bef', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/Reports/pages/HistoricalReports/HistoricalReports.vue"
export default component.exports