import { render, staticRenderFns } from "./ExtensionIcon.vue?vue&type=template&id=7b5c3fba&scoped=true&"
import script from "./ExtensionIcon.ts?vue&type=script&lang=ts&"
export * from "./ExtensionIcon.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b5c3fba",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2328814865/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b5c3fba')) {
      api.createRecord('7b5c3fba', component.options)
    } else {
      api.reload('7b5c3fba', component.options)
    }
    module.hot.accept("./ExtensionIcon.vue?vue&type=template&id=7b5c3fba&scoped=true&", function () {
      api.rerender('7b5c3fba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/Processes/components/details/DocumentsTab/TableFiles/ExtensionIcon/ExtensionIcon.vue"
export default component.exports